import {
  lightningChart,
  AxisTickStrategies,
  DataPatterns,
  SolidLine,
  SolidFill,
  ColorRGBA,
  UIOrigins,
  emptyTick,
  VisibleTicks,
  ColorHEX,
  AxisScrollStrategies,
} from "@arction/lcjs";
import React, { Component } from "react";
import { listStations } from "constants/data";
import { generateData } from "utils/generate";
const COLORS = [
  ColorRGBA(20, 50, 200),
  ColorRGBA(200, 200, 50),
  ColorRGBA(200, 50, 50),
  ColorRGBA(50, 200, 200),
];

const GENDATA = [
  generateData(100, 0, 700, 3, 80),
  generateData(100, 0, 700, 10, 50),
  generateData(100, 0, 700, 3, 80),
  generateData(100, 0, 700, 3, 50),
];
class Chart extends Component {
  constructor(props) {
    super(props);
    // Generate random ID to use as the containerId for the chart and the target div id.
    this.chartId = Math.trunc(Math.random() * 100000).toString(10);
  }

  // Define a function which creates a chart.
  createChart() {
    // Create a chartXY, the containerId determines which div the chart will be rendered to.
    const chart = lightningChart().ChartXY({ container: this.chartId });
    this.chart = chart;
    // Set the Title of the chart.
    const dateOrigin = new Date(2020, 12, 29);
    const dataFrequency = 60 * 1000;
    this.chart.getDefaultAxisX().setTickStrategy(
      // Use DateTime TickStrategy for this Axis
      AxisTickStrategies.DateTime,
      // Modify the DateOrigin of the TickStrategy
      (tickStrategy) => tickStrategy.setDateOrigin(dateOrigin)
    );
    this.chart.setPadding({ right: "5" }).setTitle("XGT Demo");

    this.chart.getDefaultAxisY().setTitle("Stations").fit(true);
    //.setInterval(0, -1, false, true);
    for (let i = 0; i < 4; i += 1) {
      const lineSeries = this.chart
        .addLineSeries({
          dataPattern: DataPatterns.horizontalProgressive,
        })
        .setName(`Train ${i + 1}`)
        .setStrokeStyle(
          new SolidLine({
            fillStyle: new SolidFill({ color: COLORS[i] }),
            thickness: 3,
          })
        );
      const data = GENDATA[i];
      lineSeries.add(
        data.map((point) => ({
          x: point.x * dataFrequency,
          y: point.y,
        }))
      );
      lineSeries.setResultTableFormatter((builder, series, x, y) => {
        // Find cached entry for the figure.
        // Parse result table content from values of 'entry'.
        return builder
          .addRow("name: ", series.getName())
          .addRow("km: ", y.toFixed(0))
          .addRow("time: ", series.axisX.formatValue(x));
      });
    }
    listStations()
      .sort((a, b) => a.y - b.y)
      .forEach((itm, index) => {
        chart
          .getDefaultAxisY()
          .addCustomTick()
          .setTextFormatter(() => `Station ${index + 1}`)
          .setGridStrokeStyle(undefined)
          .setValue(itm.y);
      });

    const legend = chart
      .addLegendBox()
      .setOrigin(UIOrigins.RightTop)
      .setPosition({ x: 90, y: 90 })
      .setMargin({ left: 10, right: 10, top: 10, bottom: 10 });
    const xAxis = chart.getDefaultAxisX();
    const xAxisConstantline = xAxis.addConstantLine();
    // Position the Constantline in the Axis Scale
    const xTime = new Date(dateOrigin);
    xAxisConstantline.setValue(0);

    const yAxis = chart.getDefaultAxisY();
    const yAxisBand = yAxis.addBand(false);
    yAxisBand.setName("Y Axis Band");
    // Position the Y Axis ConstantLine along the visible Scale of the Axis.
    // Position the Y Axis Band along the visible Scale of the Axis.
    yAxisBand.setValueEnd(200).setValueStart(100);

    // Style the Y Axis Band
    yAxisBand.setStrokeStyle(
      new SolidLine({
        thickness: 3,
        fillStyle: new SolidFill({ color: ColorHEX("#6a05") }),
      })
    );
    yAxisBand.setFillStyle(new SolidFill({ color: ColorHEX("#5b19") }));
    // Add Chart to LegendBox.
    legend.add(chart);
  }

  componentDidMount() {
    // Chart can only be created when the component has mounted the DOM as
    // the chart needs the element with specified containerId to exist in the DOM
    this.createChart();
  }

  componentWillUnmount() {
    // "dispose" should be called when the component will unmount to free all the resources used by the chart.
    this.chart.dispose();
  }

  render() {
    // render a component, which includes a div element. The chart will be created inside the div element.
    return <div id={this.chartId} className="fill"></div>;
  }
}

export default Chart;
