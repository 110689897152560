export const line = [
  { x: 0, y: 0 },
  { x: 1, y: 100 },
  { x: 2, y: 200 },
  { x: 3, y: 200 },
  { x: 4, y: 200 },
  { x: 5, y: 250 },
  { x: 6, y: 260 },
  { x: 7, y: 270 },
  { x: 8, y: 300 },
  { x: 9, y: 350 },
  { x: 10, y: 400 },
  { x: 11, y: 400 },
  { x: 12, y: 400 },
  { x: 13, y: 400 },
  { x: 14, y: 300 },
  { x: 15, y: 280 },
  { x: 16, y: 270 },
  { x: 17, y: 260 },
  { x: 18, y: 250 },
  { x: 19, y: 100 },
  { x: 20, y: 100 },
  { x: 21, y: 90 },
  { x: 22, y: 90 },
  { x: 23, y: 80 },
  { x: 24, y: 80 },
  { x: 25, y: 70 },
  { x: 27, y: 70 },
  { x: 28, y: 10 },
  { x: 29, y: 10 },
  { x: 30, y: 0 },
  { x: 31, y: 110 },
  { x: 32, y: 200 },
  { x: 33, y: 200 },
  { x: 34, y: 200 },
  { x: 35, y: 250 },
  { x: 36, y: 260 },
  { x: 37, y: 270 },
  { x: 38, y: 300 },
  { x: 39, y: 350 },
  { x: 40, y: 400 },
  { x: 41, y: 390 },
  { x: 42, y: 370 },
  { x: 43, y: 350 },
  { x: 44, y: 300 },
  { x: 45, y: 280 },
  { x: 46, y: 270 },
  { x: 47, y: 260 },
  { x: 48, y: 250 },
  { x: 49, y: 100 },
  { x: 50, y: 100 },
  { x: 51, y: 90 },
  { x: 52, y: 90 },
  { x: 53, y: 80 },
  { x: 54, y: 80 },
  { x: 55, y: 70 },
  { x: 57, y: 70 },
  { x: 58, y: 10 },
  { x: 59, y: 10 },
  { x: 60, y: 0 },
];

export const line2 = [
  { x: 0, y: 0 },
  { x: 1, y: 110 },
  { x: 2, y: 200 },
  { x: 3, y: 200 },
  { x: 4, y: 200 },
  { x: 5, y: 250 },
  { x: 6, y: 260 },
  { x: 7, y: 270 },
  { x: 8, y: 300 },
  { x: 9, y: 350 },
  { x: 10, y: 400 },
  { x: 11, y: 390 },
  { x: 12, y: 370 },
  { x: 13, y: 350 },
  { x: 14, y: 300 },
  { x: 15, y: 280 },
  { x: 16, y: 270 },
  { x: 17, y: 260 },
  { x: 18, y: 250 },
  { x: 19, y: 100 },
  { x: 20, y: 100 },
  { x: 21, y: 90 },
  { x: 22, y: 90 },
  { x: 23, y: 80 },
  { x: 24, y: 80 },
  { x: 25, y: 70 },
  { x: 27, y: 70 },
  { x: 28, y: 10 },
  { x: 29, y: 10 },
  { x: 30, y: 0 },
  { x: 31, y: 100 },
  { x: 32, y: 200 },
  { x: 33, y: 200 },
  { x: 34, y: 200 },
  { x: 35, y: 250 },
  { x: 36, y: 260 },
  { x: 37, y: 270 },
  { x: 38, y: 300 },
  { x: 39, y: 350 },
  { x: 40, y: 400 },
  { x: 41, y: 400 },
  { x: 42, y: 400 },
  { x: 43, y: 400 },
  { x: 44, y: 300 },
  { x: 45, y: 280 },
  { x: 46, y: 270 },
  { x: 47, y: 260 },
  { x: 48, y: 250 },
  { x: 49, y: 100 },
  { x: 50, y: 100 },
  { x: 51, y: 90 },
  { x: 52, y: 90 },
  { x: 53, y: 80 },
  { x: 54, y: 80 },
  { x: 55, y: 70 },
  { x: 57, y: 70 },
  { x: 58, y: 10 },
  { x: 59, y: 10 },
];

export const listStations = () => {
  const result = [];
  const issues = [];
  const min = 200;
  const max = 700;
  const step = 50;
  const number = (max - min) / step;
  for (let i = 1; i <= 10; i += 1) {
    let random = Math.floor(Math.random() * number) * step + min;
    while (issues.includes(random)) {
      random = Math.floor(Math.random() * number) * step + min;
    }
    result.push({ y: random });
    issues.push(random);
  }
  return result;
};
