export const generateData = (numberOfPoint, min, max, step, element = 20) => {
  const number = (max - min) / step;
  let temp = [];
  const sorted = [];
  for (let i = 1; i < numberOfPoint + 1; i += 1) {
    temp.push(Math.floor(Math.random() * number) * step + min);
    if (i % element === 0) {
      if ((i / element) % 2 === 0) {
        sorted.push(...temp.sort((a, b) => b - a));
      } else {
        sorted.push(...temp.sort((a, b) => a - b));
      }
      temp = [];
    }
  }
  const result = [];
  for (let i = 0; i < numberOfPoint; i += 1) {
    result.push({ x: i, y: sorted[i] });
  }
  return result;
};
